<template>
  <div :class="$style.container">
    <div class="flex align-center" :class="$style.header">
      <ws-button
        :class="$style.header__back"
        color="primary"
        text
        @click="$router.go(-1)"
      >
        <ws-icon>arrow-left</ws-icon>
        <span>{{ $t("common.back") }}</span>
      </ws-button>
    </div>
    <div :class="$style.body">
      <loader v-if="loading" center />
      <slot v-else />
    </div>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import Loader from "@/components/common/elements/Loader";

export default {
  name: "LayoutProfile",
  components: { Loader, WsIcon, WsButton },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  min-height: 100%;
}

.header {
  height: 50px;
  padding: 0 10px;
  border-bottom: 1px solid $gray-light;

  &__back {
    line-height: 1em;
    color: var(--ws-color-primary);
  }
}
</style>