<template>
  <layout-profile :loading="isLoading">
    <user-card
      :user="userProfile"
      full-info
      profile
      :avatar-upload="avatarUpload"
      @change-pass="changePassword"
      @upload="uploadAvatarHandler"
    />
  </layout-profile>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { auth, user } from "@/store/modules/store.namespaces";
import UserCard from "@/components/common/user/UserCard";
import LayoutProfile from "@/components/layouts/LayoutProfile";
import AdminApi2HttpService from "@/services/http/AdminApi2HttpService";
import { SET_USER_DESCRIPTION, SET_USER_PHOTO } from "@/store/modules/auth/mutation-types";
import { USER_PROFILE } from "@/store/modules/auth/getter-types";
import { UPLOAD_AVATAR } from "@/store/modules/user/action-types";
import { stringToHtmlLink } from "@/utils";

/**
 * Вьюха профиля текущего юзера, отображает информацию о пользователе
 *
 * @vue-data {boolean} isLoading - загрузка данных
 * @vue-computed {object} userProfile - карточка меняет отображение на вывод полной информации
 */
export default {
  name: "ViewProfile",
  components: { LayoutProfile, UserCard },
  data() {
    return {
      isLoading: false,
      avatarUpload: false,
    };
  },
  computed: {
    ...mapGetters(auth, {
      userProfile: USER_PROFILE,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(auth, {
      setUserDescription: SET_USER_DESCRIPTION,
      setUserPhoto: SET_USER_PHOTO,
    }),
    ...mapActions(user, {
      uploadAvatar: UPLOAD_AVATAR,
    }),
    /**
     * Инициализация вьюхи, загрузка всего что ей нужно
     *
     * @returns {Promise<void>}
     */
    async init() {
      try {
        this.isLoading = true;
        await this.getAdditionInfo();
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Дозагрузка описания текущего юзера
     * TODO удалить как только будет бек решение временное
     *
     * @returns {Promise<void>}
     */
    async getAdditionInfo() {
      if (this.userProfile.description) return;
      const { data } = await AdminApi2HttpService.get(`user/${this.userProfile.userId}/profile`);
      this.setUserDescription(stringToHtmlLink(data.user_description));
    },
    changePassword() {
      // смена пароля
    },
    /**
     * Обработка загрузки аватара
     *
     * @param {Blob} avatar - файл аватарки
     * @returns {Promise<void>}
     */
    async uploadAvatarHandler(avatar) {
      try {
        this.avatarUpload = true;
        await this.uploadAvatar(avatar);
      } catch (e) {
        this.$notify({
          group: "roomSystem",
          type: "error",
          duration: 3000,
          title: this.$t("profile.uploadFailed"),
        });
      } finally {
        this.avatarUpload = false;
      }
    },
  },
};
</script>
