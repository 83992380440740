<template>
  <layout-profile>
    <loader v-if="isLoading" center :animated="false" />
    <user-card v-else-if="listener.id" :user="listener" full-info />
    <div v-else-if="error.message" :class="$style.error">{{ error.message }}</div>
  </layout-profile>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { listeners } from "@/store/modules/store.namespaces";
import { LISTENER_WITH_PROFILE } from "@/store/modules/listeners/getter-types";
import { GET_LISTENER_PROFILE } from "@/store/modules/listeners/action-types";
import { SET_ERROR } from "@/store/modules/listeners/mutation-types";
import Loader from "@/components/common/elements/Loader";
import UserCard from "@/components/common/user/UserCard";
import LayoutProfile from "@/components/layouts/LayoutProfile";

/**
 * Компонент страницы профиля пользователя
 * @vue-data {Boolean} isLoading - ожидаем загрузки профиля
 * @vue-computed {Object} error - объект ошибки содержащий код и сообщение
 * @vue-computed {Object} user - подготовленный объект пользователя для отображения
 */
export default {
  name: "ViewListenerProfile",
  components: {
    LayoutProfile,
    Loader,
    UserCard,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(listeners, ["error"]),
    ...mapGetters(listeners, {
      listener: LISTENER_WITH_PROFILE,
    }),
  },
  async created() {
    if (!this.listener.id) {
      this.isLoading = await this.getListenerProfile(+this.$route.params.listenerId);
    } else {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.setError({ code: 0, message: "" });
  },
  methods: {
    ...mapActions(listeners, {
      getListenerProfile: GET_LISTENER_PROFILE,
    }),
    ...mapMutations(listeners, {
      setError: SET_ERROR,
    }),
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  flex: 1 0 auto;
  width: 100%;
}

.error {
  margin: auto;
  font-size: 20px;
}

.header {
  padding: 10px 15px;
  border-bottom: 1px solid $gray-light;
}
</style>
